import * as React from "react";
import Layout from "../components/layout";
import Arrow from "../assets/icons/arrow-right.svg";
import { v4 as uuidv4 } from "uuid";
import { HistoryLocation } from "use-query-params";

// markup
const IndexPage = ({ location }: HistoryLocation) => {
  const uid = uuidv4();
  const url = location.href
    ? location.href
    : process.env.CROSSSKILL_CALLBACK_URL;
  const testUri = process.env.CROSSSKILL_TEST_URI!;
  return (
    <Layout pageTitle="Service Providers">
      <div className="mx-auto flex flex-col max-w-5xl">
        <img src="https://pascal-project.eu/sites/default/files/2021-04/1%20-%20Guide%20to%20Autonomy.jpg"></img>
        <div className="my-8 px-8 xl:px-0">
          <h1>
            Connected and Autonomous Vehicle knowledge: Where do you stand?
          </h1>
          <p>
            This <span className="font-bold">self-assessment</span> tool allows
            you to take stock of your CAV - Connected and Autonomous Vehicles.-
            knowledge and interest. Take the questionnaire below and then,
            access personalised recommendations that will help you step up your
            knowledge about CAVs.
          </p>
          <p>
            This self-assessment should take you only{" "}
            <span className="font-bold">4 minutes</span> to fulfil.
          </p>
          <p>
            Note that once you have answered a question, you CANNOT go back and
            modify the answer. We invite you to answer the questions as
            spontaneously as possible. The more honestly you answer, the more
            accurate the results are!
          </p>
          <div className="my-8"></div>
          <a
            href={`${process.env.CROSSSKILL_BASE_URL}/taoSkillcard/GuestAccess/guest?uri=${testUri}&uid=${uid}&lg=en-US&redirect=${url}results?uid=${uid}`}
          >
            <button className="mx-auto rounded-full bg-dark text-white p-4 text-center inline-flex items-center">
              Get started
              <Arrow className="w-5 h-5"></Arrow>
            </button>
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
