import { Link } from "gatsby";
import React, { FC } from "react";
import PascalLogo from "../../assets/icons/pascal.svg";

interface HeaderProps {}

const Header: FC<HeaderProps> = () => (
  <div className="bg-dark flex-shrink-0 flex sm:flex-col w-full sm:w-[220px] h-[50px] sm:h-full sm:min-h-full p-4">
    <div className="flex items-center mt-[12.8px] w-full">
      <a href="/">
        <PascalLogo className="block w-4/5 h-[50px] sm:h-auto" />
      </a>
    </div>
    <div className="hidden sm:block text-white mt-8 py-2 text-right mr-4 text-opacity-80">
      <a target={"_blank"} href="https://pascal-project.eu">
        About PAsCAL Project
      </a>
    </div>
    <div className="hidden sm:block text-white mt-8 py-2 text-right mr-4 text-opacity-80">
      <a target={"_blank"} href="https://pascal-project.eu/guide-to-autonomy">
        Guide to Autonomy
      </a>
    </div>
    <div className="text-white sm:mt-8 py-2 text-sm sm:text-base  text-right mr-4 text-opacity-80">
      <Link to="/privacy-policy">Privacy Policy</Link>
    </div>
    <div className="hidden sm:block grow md:flex flex-col-reverse text-white text-lg">
      <div className="text-white p-2">
        <p className="text-xs">
          © Luxembourg Institute of Science and Technology 2022{" "}
        </p>
      </div>
    </div>
  </div>
);

export default Header;
