import * as React from "react";
import Header from "./header/header";

interface Props {
  pageTitle: string;
  children: any;
}

const Layout = ({ pageTitle, children }: Props) => {
  return (
    <div className="flex flex-col sm:flex-row w-full h-full min-h-full">
      <title>PAsCAL G2A - {pageTitle}</title>
      <Header></Header>
      <main className="flex w-full bg-light-gray">{children}</main>
    </div>
  );
};

export default Layout;
